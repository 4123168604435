@import './../../scss/constants';

.wrapper {
  background: $color-lightest;
  border-left: $color-accent 0.25rem solid;
  padding: 0.75em 0.5em;
  font-size: rem(32);
  font-family: $heading-font-family;
  font-weight: $heading-weight-medium;
  line-height: $heading-line-height;
  margin: 1em 0;

  @include min-width(768) {
    font-size: rem(46);
  }

  > p {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
  }
}

.cite {
  display: block;
  font-size: rem(22);
  font-style: normal;
  margin-bottom: -0.25em;
  margin-top: 1em;

  &::before {
    content: '— ';
  }
}
