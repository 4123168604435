@import './../../scss/constants';

.group {
  display: block;
  margin-bottom: rem(16);

  &:last-child {
    margin-bottom: 0;
  }

  span {
    display: block;
    font-size: rem(16);
    font-weight: 500;
    margin-bottom: rem(8);
  }

  textarea {
    display: block;
    width: 100%;
    background: rgba($color-darkest, 0.04);
    border-radius: rem(4);
    padding: rem(14);
    box-sizing: border-box;
  }

  .characterCount {
    font-size: rem(14);
    text-align: right;
  }
}
