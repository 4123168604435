@import './../../scss/constants';

.field {
  display: grid;
  margin: 0 auto 1.5rem;

  span {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  input,
  .selectContainer {
    font-family: inherit;
    border: none;
    font-size: 1.125rem;
    min-height: 3.5rem;
    line-height: 1.5;
    font-weight: normal;
    resize: vertical;
    display: block;
    background: $color-lightest;
    border-radius: rem(4);
    padding: rem(7) rem(14);
    box-sizing: border-box;
  }

  .selectContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    select {
      // Make the select invisible and place it over the replacement elements
      // so it captures all the clicks
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 100%;
      border: none;
      background: none;
      position: absolute;
      top: 0;
      left: 0;
      // font-size drives the size of the options in the list
      font-size: 1.25rem;
      // Transparent text instead of zeroing the opacity so we can reuse the
      // default focus state (it disappears with 0 opacity)
      color: transparent;
      height: 100%;

      option {
        // Without this Firefox will use transparent color for options too
        color: black;
      }
    }
  }
}
