@import './../../scss/constants';

.mainContainer {
  margin-top: 3rem;
}

.backButton {
  display: flex;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  font: inherit;
  font-weight: bold;
  cursor: pointer;
  opacity: .6;
  transition: opacity 200ms ease-in-out;
  margin-bottom: 2rem;
  padding: 0;

  svg {
    height: 1.3rem;
    margin-right: 1rem;
  }

  &:hover, &:focus {
    opacity: 1;
  }
}
