@import './../../scss/constants';

.container {
  border-radius: rem(8);
  border: rem(1) solid rgba(0, 0, 0, 0.05);
  background: white;
  margin-bottom: rem(25);
}

.header {
  border-bottom: rem(1) solid #efefef;

  @include min-width(1024) {
    display: flex;
  }
}
.titleBox {
  display: flex;
  align-items: center;
  padding: rem(20);

  img {
    margin-right: rem(20);
  }

  @include min-width(1024) {
    display: block;
    width: rem(120);
  }
}
.titleBox,
.nowBox {
  @include min-width(1024) {
    border-right: rem(1) solid #efefef;
  }
}
.nowBox,
.futureBox {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding: rem(10);
}

.title {
  font-family: $heading-font-family;
  font-weight: $heading-weight-bold;
  font-size: rem(24);
  margin: 0;
  margin-top: rem(5);
  line-height: 1;
}
.score {
  position: relative;
  border-radius: rem(5);
  background: #f5f5f5;
  border: rem(1) solid #e5e5e5;
  margin-right: rem(10);

  &:focus-within {
    &,
    .label {
      border-color: darken(#e5e5e5, 15%);
    }
  }

  .label {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: rem(16);
    font-weight: 500;
    line-height: 1;
    border-bottom: rem(1) solid #e5e5e5;
    padding-top: rem(8);
    padding-bottom: rem(5);
    font-family: $heading-font-family;
    font-weight: $heading-weight-medium;
  }

  .selectContainer {
    position: relative;
    width: 3.75rem;
    margin: 0;
    padding: 0;
  }

  .value {
    display: block;
    font-family: $heading-font-family;
    font-weight: $heading-weight-bold;
    font-size: 2rem;
    line-height: 1;
    padding: 0.375rem 0.5rem 0.125rem;
    letter-spacing: -0.025em;
  }

  .value100 {
    margin-left: -0.03125em;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    border: none;
    background: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.25rem;
    opacity: 0;
    height: 100%;

    &:focus {
      border: 0;
      outline: none;
    }
  }

  .arrow {
    position: absolute;
    top: 50%;
    right: 0.3125rem;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

:global(body[dir='rtl']) {
  .score {
    .arrow {
      right: auto;
      left: 0.3125rem;
    }
  }
}

.descriptionWrapper {
  width: 100%;
  textarea {
    box-sizing: border-box;
    width: 100%;
  }

  .characterCount {
    font-size: rem(14);
    text-align: right;
  }
}

.scoreDescription {
  flex: 1;
  padding: rem(5) rem(10);
}

.myPlan {
  padding: 0 rem(15);
  overflow: hidden;

  &.hidden {
    display: none;
  }

  @include min-width(1024) {
    padding: 0 rem(40);
  }
}

.subsectionTitle {
  font-family: $heading-font-family;
  font-weight: $heading-weight-bold;
  font-size: rem(32);
  margin-bottom: rem(16);
  margin-top: rem(48);
  text-transform: uppercase;
}

.expandButton {
  display: block;
  width: rem(160);
  padding-top: rem(9 + 2); // Vertically centering the text using paddings
  padding-bottom: rem(9 - 2);
  border-radius: rem(4);
  border: rem(1) solid transparent;
  color: $color-lightest;
  font-family: $heading-font-family;
  font-weight: $heading-weight-bold;
  font-size: rem(22);
  line-height: 1;
  text-transform: uppercase;
  outline: none;
  box-shadow: none;
  margin: rem(8) auto;
  cursor: pointer;
  text-shadow: rgba(black, 0.1) rem(1) rem(1) rem(2);

  &.expandedMargin {
    margin: rem(24) auto;
  }

  &.light {
    color: $color-darkest;
    background: none !important;
    border-color: rgba($color-darkest, 0.1);
    text-shadow: none;
  }

  &:focus {
    border-color: rgba($color-darkest, 0.25);
  }
}
