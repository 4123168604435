$heading-font-family: 'Teko', sans-serif;
$heading-weight-medium: 400;
$heading-weight-bold: 500;
$heading-line-height: 1.1;

$body-line-height: 1.5;
$body-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$body-weight-regular: 400;
$body-weight-medium: 500;
$body-weight-bold: 600;

$color-lightest: #FFF;
$color-light: #F5F5F5;
$color-darkest: #000;
$color-accent: #2BAECD;
$color-warning: #E5AC00;

@mixin min-width($pixels) {
  @media screen and (min-width: #{$pixels / 16}rem) {
    @content;
  }
}

@mixin max-width($pixels) {
  @media screen and (max-width: #{($pixels - 1) / 16}rem) {
    @content;
  }
}

@function rem($pixels) {
  @return $pixels / 16 * 1rem;
}
