.wrapper {
  color: black;
}

.wheel {
  outline: 2px red;
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.wheel::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.chunk {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wedge {
  transition: transform 500ms;
  transform-origin: center center;
}
