@import './../../scss/constants';

.wrapper {
  box-sizing: border-box;
  background: $color-lightest;
  border-left: $color-warning 0.25rem solid;
  padding: 1rem;
  margin: 3rem auto 4rem;
  min-height: 6rem;
  max-width: 52rem;
  width: 100%;

  > svg {
    display: block;
    margin: auto 2rem 1rem 0;
    flex-shrink: 0;
    height: auto;
    width: 2.375rem;
  }

  > p {
    margin: auto 0;
    font-size: inherit;
    line-height: inherit;
    max-width: 42em;
    font-size: 1.125rem;
  }

  @include min-width(768) {
    display: flex;
    padding: 2rem;

    > svg {
      margin-bottom: auto;
      width: auto;
    }
  }
}

:global(body[dir='rtl']) {
  .wrapper {
    > svg {
      margin: auto 0 1rem 2rem;
    }
  }
}
