@import 'scss/constants';

body {
  background: $color-light;
  color: $color-darkest;
  margin: 0;
  font-family: $body-font-family;
  line-height: $body-line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: inherit;
  border: none;
  font-size: 1rem;
  min-height: 4rem;
  max-height: 52rem;
  line-height: 1.5;
  font-weight: normal;
  resize: vertical;

  &::placeholder {
    font-style: italic;
  }
}

h2 {
  font-family: $heading-font-family;
  font-weight: $heading-weight-bold;
  font-size: rem(56);
  line-height: 1.1;
  margin: 0;
  margin-bottom: rem(15);
}

hr {
  border: 0;
  height: rem(1);
  background: rgba($color-darkest, 0.2);
  margin: rem(65) 0;
}

.group {
  margin: 0 auto;
  max-width: 60rem;
  width: 95%;
  position: relative;
}

.sectionWithForm {
  @include min-width(1024) {
    display: grid;
    grid-template-columns: 35% auto;
    gap: 2.5rem;

    .container {
      margin-top: 0;
    }
  }
}

.container {
  border-radius: 0.5rem;
  border: 0.0625 solid rgba(0, 0, 0, 0.05);
  background: white;
  padding: 0.875rem;
  margin-top: 2rem;

  @include min-width(1024) {
    padding: 2.5rem;
  }

  &:last-child {
    margin-bottom: 4rem;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

p {
  font-size: 1rem;
  max-width: 48em;
  line-height: 1.6;

  @include min-width(768) {
    font-size: 1.25rem;
  }
}
