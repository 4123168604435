@import './../../scss/constants';

$border-width: rem(1);

.wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 32rem;

  @include min-width(768) {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    max-width: 64rem;
  }
}

.fieldset {

  & + & {
    margin-top: 1rem;
  }
}

.label {
  font-family: $heading-font-family;
  font-weight: $heading-weight-bold;
  font-size: 1.5rem;
  line-height: 1.5;
}

.slider {
  border: $border-width solid #DDD;
  border-radius: 0.75rem;
  font-family: $body-font-family;
  background: #E9E9E9;
  display: block;
  position: relative;

  &:hover {

    .bar {

      &::after {
        transform: scale(1.334);
      }
    }
  }

  &:focus-within {

    .bar {

      &::after {
        transform: scale(1.667);
      }
    }
  }
}

.bar {
  background: currentColor;
  transition: 50ms ease-in-out;
  border-radius: 0.75rem;
  line-height: 1.425rem;
  margin: $border-width * -1; // Ensures that the bar is properly positioned
  min-width: 2.5rem;
  max-width: calc(100% + #{$border-width * 2});
  display: block;
  height: 1.5rem;
  text-align: right;
  white-space: nowrap;
  position: relative;

  &::after {
    background: $color-lightest;
    border-radius: 50%;
    content: '';
    display: block;
    height: 0.375rem;
    width: 0.375rem;
    vertical-align: middle;
    transition: 100ms ease-in-out;
    position: absolute;
    top: 50%;
    margin-top: -0.1875rem;
    right: 0.5rem;
  }
}

.tooltip {
  color: $color-lightest;
  font-size: 0.75rem;
  font-weight: $body-weight-bold;
  min-width: 1rem;
  display: inline-block;
  font-variant: tabular-nums;
  letter-spacing: -0.05em;
  padding-right: 1.25rem;
}

.input {
  border: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  background: none;
  -webkit-appearance: none;
  opacity: 0.25;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
  }

  &::-moz-range-thumb {
    width: 1rem;
  }

  &::-ms-thumb {
    width: 1rem;
  }
}

.wheel {

  @include max-width(768) {
    margin-top: 2rem;
  }
}
