@import './../../scss/constants';

.intro {
  font-size: 1.25rem;
  font-weight: $body-weight-medium;
  margin: 1.5em 0;

  @include min-width(768) {
    font-size: 1.5rem;
  }
}

.pyramidWrapper {
  @include min-width(768) {
    align-items: center;
    display: grid;
    grid-template-columns: 5fr 4fr;
    grid-gap: 4rem;

    .intro {
      &:first-child {
        margin-top: -0.33em;
      }
    }
  }
}

.pyramidImage {
  display: block;
  height: auto;
  margin: 2rem auto;
  width: 100%;

  @include min-width(768) {
    margin-top: 0;
    grid-column: 1;
    grid-row: 1;
  }
}

.columnsHeadings {
  display: flex;
  margin: 0 0.375rem;
  font-family: $heading-font-family;
  font-weight: $heading-weight-bold;
  font-size: 1.5rem;
  margin: 5rem 0 1rem;

  @include max-width(1023) {
    display: none;
  }
}
.columnTitle,
.columnNow,
.columnFuture {
  padding: 0 rem(20);
}
.columnTitle {
  width: rem(120);
  padding: 0 rem(20);
}
.columnNow,
.columnFuture {
  flex: 1;
  padding: 0 rem(10);
}

.translationsBanner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.3rem;
  margin: 3rem auto;

  svg {
    width: 2em;
    margin-right: rem(10);
  }

  .languageLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: rem(10);
  }

  .languageSelect {
    min-width: rem(150);
  }

  @include max-width(768) {
    flex-direction: column;

    .languageLabel {
      margin-bottom: rem(10);
    }
  }
}

:global(body[dir='rtl']) {
  .languageLabel {
    margin-left: rem(10);
    gap: rem(10);
  }
}
