@import './../../scss/constants';

.field {
  display: grid;
  margin: 0 auto 1.5rem;

  span {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  input,
  .selectContainer {
    font-family: inherit;
    border: none;
    font-size: 1.125rem;
    min-height: 3.5rem;
    line-height: 1.5;
    font-weight: normal;
    resize: vertical;
    display: block;
    background: rgba($color-darkest, 0.04);
    border-radius: rem(4);
    padding: rem(7) rem(14);
    box-sizing: border-box;
  }

  .selectContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    select {
      // Make the select invisible and place it over the replacement elements
      // so it captures all the clicks
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 100%;
      border: none;
      background: none;
      position: absolute;
      top: 0;
      left: 0;
      // font-size drives the size of the options in the list
      font-size: 1.25rem;
      // Transparent text instead of zeroing the opacity so we can reuse the
      // default focus state (it disappears with 0 opacity)
      color: transparent;
      height: 100%;

      option {
        // Without this Firefox will use transparent color for options too
        color: black;
      }
    }
  }
}

.privacy {
  display: grid;
  grid-template-columns: min-content auto;
  margin: 2rem auto;
  text-align: center;
  font-size: 0.875rem;
  text-align: justify;
  user-select: none;

  a {
    color: $color-accent;
    font-weight: 500;
  }

  .termsCondition {
    margin-top: 0.5rem;
  }
}

.checkbox {
  background: #e9e9e9;
  color: rgba($color-lightest, 0.5);
  border: rem(1) solid #dddddd;
  border-radius: rem(2);
  height: 1rem;
  width: 1rem;
  margin: 0.05em 0.8em 0 0;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  transition: 200ms ease-in-out;
  transition-property: background, color, border-color;

  .privacy:hover &,
  &:hover,
  &:focus-within {
    border-color: rgba($color-darkest, 0.5);
  }

  input {
    appearance: none;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &.checked {
    background: $color-accent;
    color: $color-lightest !important;
    border-color: $color-accent;

    .privacy:hover &,
    &:hover,
    &:focus-within {
      border-color: darken($color-accent, 10%) !important;
    }
  }

  path {
    stroke: currentColor;
  }
}

.generateButton {
  display: block;
  color: $color-lightest;
  border: 0;
  border-radius: 0.25rem;
  background: $color-accent;
  padding: rem(11 + 3) rem(24) rem(11);
  min-width: 11.25rem;
  font-family: $heading-font-family;
  font-weight: $heading-weight-bold;
  font-size: rem(24);
  margin: auto;
  text-transform: uppercase;
  outline: none;
  box-shadow: none;
  transition: 200ms ease-in-out;
  transition-property: background, opacity, transform;
  text-shadow: rgba(black, 0.1) rem(1) rem(1) rem(2);

  &:not([disabled]) {
    cursor: pointer;

    &:hover,
    &:focus {
      background: $color-darkest;
    }
  }

  &.generateButtonLoading {
    background: $color-darkest;
    opacity: 0.5;
  }

  &:not(.generateButtonLoading) {
    &[disabled] {
      opacity: 0.5;
      transform: scale(0.925);
    }
  }
}

.submitArea {
  position: relative;
}

.success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem 2rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  color: darkgreen;
  background: lightgreen;
  transition: opacity 200ms ease-in-out;

  svg {
    height: 1.5rem;
    margin-right: 0.75rem;

    path {
      fill: currentColor;
    }
  }

  &[hidden] {
    display: none;
  }
}

:global(body[dir='rtl']) {
  .checkbox {
    margin: 0.05em 0 0 0.8em;
  }
}
