@import './../../scss/constants';

.wrapper {
  background: linear-gradient(to right, black 25%, rgba(black, 0.4) 50%),
    black url('./hero-background.jpg') left 33.3% / cover no-repeat;
  display: flex;
  flex-direction: column;
  color: white;
  min-height: calc(10rem + 15vw);
  justify-content: flex-end;
  margin-bottom: 3rem;
}

.title {
  display: flex;
  flex-direction: column;
  font-family: $heading-font-family;
  margin: 0.6em 0;
  font-size: 4.5rem;
  line-height: 1;
  text-transform: uppercase;

  @include min-width(768) {
    font-size: 6.25rem;
  }
}

.h1 {
  display: block;
  font-weight: $heading-weight-bold;
  margin-top: 0.03em;
  margin-bottom: -0.14em;
}

.lead {
  color: $color-accent;
  font-weight: $heading-weight-medium;
  order: -1;
  font-size: 0.3em;
  margin-bottom: 0.2em;
}
